
import appel from "../assets/img/appel1.png";
import whatsapp from "../assets/img/whatsapp1.png";
import email from "../assets/img/email1.png"
import { motion } from "framer-motion";
import { Contact } from "./Contact";
import React, { useState, useEffect } from 'react';

import logowhite from "../assets/img/logowhite.png";
export const Footer = () => {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 500);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 500);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="footer">
      <div className="d-flex justify-content-center">
      <Contact /></div>

        <div className="d-flex">
          <motion.img src={logowhite} alt="banner logo" className="footer-logo"
         initial={{
          opacity: isWideScreen ? 0 : 1,
          y: isWideScreen ? -50 : 0,
        }}
        whileInView={isWideScreen ? {
          opacity: 1,
          y: 0,
          transition: {
            duration: 1,
          },
        } : {}}
        viewport={{ once: true }}
      />
        </div>
        <div className="media_links" id="media_links">
        <div className="row1" id="row1">
          <motion.h1
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.2,
            },
          }}

          viewport={{ once: true }}>Reach us out</motion.h1>
          <motion.div className="reach d-flex justify-conter-between align-items-center gap-2"
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.2,
            },
          }}

          viewport={{ once: true }}>
            <div className="image"><img src={appel} alt="appel logo"/></div>
            <a href="tel:01573 9012587" target="_blank" rel="noreferrer" ><p> +49 01573 9012587</p></a>
          </motion.div>
          <motion.div className=" reach d-flex justify-conter-between align-items-center gap-2"
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.4,
            },
          }}

          viewport={{ once: true }}>
            <div className="image"><img src={whatsapp} alt="appel logo"/></div>
            <a href="tel:+49 00216 97 164 393" target="_blank"  rel="noreferrer"><p>  00216 97 164 393</p></a>
          </motion.div>
          <motion.div className="reach d-flex justify-conter-between align-items-center gap-2"
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.6,
            },
          }}

          viewport={{ once: true }}>
            <div className="image"> <img src={email} alt="appel logo"/></div>
            <a href="mailto:direction@mammut-corporation.com" rel="noreferrer" target="_blank" ><p>direction@mammut-corporation.com</p></a>
          </motion.div>
        </div>
        <div className="bar"></div>
        <div className="row2">
          <motion.h1
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
            },
          }}

          viewport={{ once: true }}
          >Follow us</motion.h1>
          <a href="https://www.facebook.com/Mammouthweb" target="_blank" ><motion.h2
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
            },
          }}

          viewport={{ once: true }}>Facebook</motion.h2></a>
          <a href="https://www.instagram.com/mammut.web.solutions/?hl=fr" target="_blank"><motion.h2
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.2,
            },
          }}

          viewport={{ once: true }}>Instagram</motion.h2></a>
          <a href="https://www.linkedin.com/company/mammut-web-solutions/" target="_blank"><motion.h2
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.4,
            },
          }}

          viewport={{ once: true }}>Linkedin</motion.h2></a>
        </div>
      </div>
      <p className="p-2">&copy; 2024 Mammut Web Solutions. Tous droits réservés.</p>
      </div>

  );
};
