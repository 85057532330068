import React from "react";
import "./innovation.css";
import creativity from "../img/creativity_animation.gif";
import creativityIcon from "../img/creativity_icon.png";
import { motion } from "framer-motion";

const Creativity = () => {
  return (
    <div className="innovation">
      <div className="innovation_img">
        <motion.img
          src={creativity}
          alt="innovation"
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          viewport={{ once: true }}
        />
      </div>
      <div className="innovation_title">
        <motion.h1
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          viewport={{ once: true }}
        >
          CREATIVITY
        </motion.h1>
      </div>
      <div className="innovation_text">
        <motion.img
          src={creativityIcon}
          alt="innovationIcon"
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.p
          initial={{
            opacity: 0,
            x: 30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          Creativity within a team is a catalyst for innovation and progress. It
          encourages us to think outside the box, leading to unique solutions
          and breakthrough ideas. When creativity is valued, we are more likely
          to take calculated risks, experiment with new approaches, and embrace
          change. This not only enhances problem-solving capabilities but also
          fosters a dynamic and adaptable work environment.
        </motion.p>
      </div>
    </div>
  );
};

export default Creativity;
