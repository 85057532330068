import React ,{useState}from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from "./Components/NavBar";
import Solutions  from "./Pages/solutions/Solutions";
import Home from "./Pages/home/Home";
import OurAdvantages from "./Pages/ouradvantages/OurAdvantages" ;
import Contact from "./Pages/contact/Contact" ;

import Ourproducts from "./Pages/products/Ourproducts" ;
import "./styles.css";
import Beoneofus from "./Pages/beoneofus/Beoneofus";
import Success from "./Pages/success/Success";

export default function App() {
  const [loading,setloading]= useState(true);
  const spinner = document.getElementById('spinner');
  if (spinner){
    setTimeout(()=>{
      spinner.style.display="none";
      setloading(false);
    },2000);
  }
  return (
    
    !loading && (<div>
      <NavBar />
      <Routes>
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/" exact element={<Home />} />
        <Route path="/beoneofus" element={<Beoneofus />}/>
        <Route path="/contact" element={<Contact />}/>
        <Route path="/success" element={<Success/>}  />
        <Route path="/ouradvantages" element={<OurAdvantages />} />
        <Route path="/ourproducts" element={<Ourproducts />} />
      </Routes>
    </div>)
  );
}


