import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { motion } from "framer-motion";
import { Container } from 'react-bootstrap';
import './citiesslider.scss';
export const CitiesSlider = (props) => {
  const IMAGE_PARTS = 4;
  const AUTOCHANGE_TIME = 1000000;

  const [activeSlide, setActiveSlide] = useState(-1);
  const [prevSlide, setPrevSlide] = useState(-1);
  const [sliderReady, setSliderReady] = useState(false);
  let changeTO = null;

  useEffect(() => {
    runAutochangeTO();
    setTimeout(() => {
      setActiveSlide(0);
      setSliderReady(true);
    }, 0);

    return () => {
      window.clearTimeout(changeTO);
    };
  }, []);

  const runAutochangeTO = () => {
    changeTO = setTimeout(() => {
      changeSlides(1);
      runAutochangeTO();
    }, AUTOCHANGE_TIME);
  };

  const changeSlides = (change) => {
    window.clearTimeout(changeTO);
    const { length } = props.slides;
    const prevSlideValue = activeSlide;
    let newActiveSlide = prevSlideValue + change;
    if (newActiveSlide < 0) newActiveSlide = length - 1;
    if (newActiveSlide >= length) newActiveSlide = 0;
    setActiveSlide(newActiveSlide);
    setPrevSlide(prevSlideValue);
  };

  return (
    <container>
    <div className={classNames('slider', { 's--ready': sliderReady })}>
      <div>
        <motion.p className="slider__top-heading"
        initial={{
          opacity: 0,
          x: -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.4,
          },
        }}
        
        viewport={{ once: true }}>Find us in those ERAs</motion.p>
        <motion.p className="slider__top-heading2"
        initial={{
          opacity: 0,
          x: -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.6,
          },
        }}
        
        viewport={{ once: true }}>This is where we create magic</motion.p>
      </div>
      <div className='slider__top-more'>
      
        <motion.h1 className='slider__top-button'
        initial={{
          opacity: 0,
          x: -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.8,
          },
        }}
        
        viewport={{ once: true }}>MORE ABOUT US</motion.h1>
        <motion.div className="slider__top-arrow1"
        initial={{
          opacity: 0,
          x: -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.1,
          },
        }}
        
        viewport={{ once: true }}
        >
                <svg height="100" width="90">
                  <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                  <rect x="58" y="25" width="4" height="45" fill="#CC5803" />
                </svg>

                <div className="circle1"></div>
         </motion.div>
      </div>
     
      <div className="slider__slides">
        {props.slides.map((slide, index) => (
          <div
            className={classNames('slider__slide', { 's--active': activeSlide === index, 's--prev': prevSlide === index })}
            key={slide.country}
          >
            <div className="slider__slide-content">
              <h1 className="slider__slide-subheading">{slide.city}</h1>
              <h1 className="slider__slide-heading">
                {slide.country.split('').map((l, i) => (
                  <span key={i}>{l}</span>
                ))}
              </h1>
              {/* <p className="slider__slide-readmore">MORE ABOUT US</p> */}
            </div>
            <div className="slider__slide-parts">
              {[...Array(IMAGE_PARTS).fill()].map((x, i) => (
                <div className="slider__slide-part" key={i}>
                  <div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide.img})` }} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="slider__control" onClick={() => changeSlides(-1)} />
      <div className="slider__control slider__control--right" onClick={() => changeSlides(1)} />
    </div>
    </container>
  );
};


