import bannerImg from "../assets/img/banner-img.png";

import "animate.css";
import ERA from "../assets/img/ERA.png";
import CREATEYOURGROWTH from "../assets/img/CREATEYOURGROWTH.png";
import StarAnimation from "./StarAnimation";

import { motion } from "framer-motion";
import { fadeIn } from "./variants";
function Scrollfunction() {
  window.scrollBy(0, 700);
 
}


export const Banner = () => {
  
  return (
    <section
      className="banner d-flex align-items-center justify-content-center"
      id="welcome"
    >
      <StarAnimation />
      {/* <ParticlesContainer /> */}
      <div className="d-flex align-items-center justify-content-center p-5">
        <div className="banner_first d-flex justify-content-between  align-self-center gap-0">
          <div className="d-flex justify-content-center flex-column align-self-center align-items-center">
            <div className=" container-text my-2 d-flex flex-column  gap-1">
              <div>
                <motion.img
                  src={CREATEYOURGROWTH}
                  alt="Headerline1 Img"
                  className="image1"
                  initial={{
                    opacity: 0,
                    x: -50,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.6,
                    },
                  }}
                  
                  viewport={{ once: true }}
                />
              </div>
              <div>
                <motion.img src={ERA} alt="Headerline2 Img"
                 className="image2"
                 initial={{
                  opacity: 0,
                  x: -50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.8,
                  },
                }}
                viewport={{ once: true }}/>
              </div>
              <motion.div className="banner-pargraph mt-3"
              initial={{
                opacity: 0,
                x: -50,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: true }}>
                
                <p>
                  Our company serves as the forefront of innovation, offering
                  a comprehensive suite of services in web development{" "}
                and creative design solutions.</p>
              </motion.div>
            </div>
            <div className="d-flex flex-column align-items-center gap-0">
              <motion.h1 className=""
              initial={{
                opacity: 0,
                y: 50,
              }}
              whileInView={{
                opacity: 1.2,
                y: 0,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: true }}>Meet our standards</motion.h1>
              <motion.div className="arrow" onClick={Scrollfunction} 
              initial={{
                opacity: 0,
                y: 50,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: true }}>
                <svg height="100" width="90">
                  <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                  <rect x="58" y="25" width="4" height="165" fill="#CC5803" />
                </svg>

                <div className="circle"></div>
              </motion.div>
            </div>
          </div>
          <div className="container-image  align-self-center">
            <motion.img src={bannerImg} 
            alt="Header Img"
            initial={{
              opacity: 0,
              x: -50,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 1,
              },
            }}
            viewport={{ once: true }} />
          </div>
        </div>
      </div>
    </section>
  );
};
