import React from "react";
import { motion } from "framer-motion";
import "./Bannerbeoneofus.css";
import beoneofus_animation from "../img/beoneofus_animation.gif";

const Bannerbeoneofus = () => {
  return (
    <div>
    <div className="bannerbeoneofus">
      <div className="bannerbeoneofus_title">
        <motion.h1 
        initial={{
          opacity: 0,
          y: -50,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.8,
          },
        }}
        viewport={{ once: true }}>
          The <span >epic journey </span>of any great team Begins with a single,{" "}
          <span>extraordinary</span>
        </motion.h1>
      </div>
      
    </div>
    <div className="bannerbeoneofus_next">
    <div className="bannerbeoneofus_animation">
        <motion.img

          src={beoneofus_animation}
          alt="beoneofus_animation"
          className="pb-5"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.6,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.img
          src={beoneofus_animation}
          alt="beoneofus_animation"
          className="pt-5"
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.7,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.img
          src={beoneofus_animation}
          alt="beoneofus_animation"
          className="pb-5"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.8,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.img
          src={beoneofus_animation}
          alt="beoneofus_animation"
          className="pt-5"
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.9,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.img
          src={beoneofus_animation}
          alt="beoneofus_animation"
          className="pb-5"
          initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.6,
              },
            }}
            viewport={{ once: true }}
        />
        <motion.img
          src={beoneofus_animation}
          alt="beoneofus_animation"
          className="pt-5"
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.1,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.img
          src={beoneofus_animation}
          alt="beoneofus_animation"
          className="pb-5"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.2
            },
          }}
          viewport={{ once: true }}
        />
        <motion.img
          src={beoneofus_animation}
          alt="beoneofus_animation"
          className="pt-5"
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.3,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.img
          src={beoneofus_animation}
          alt="beoneofus_animation"
          className="pb-5"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1.4,
            },
          }}
          viewport={{ once: true }}
        />
      </div>
      <div className="BePartofourTeam">
        <motion.h2 initial={{
          opacity: 0,
          y: -50,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: {
            duration: 1,
          },
        }}
        viewport={{ once: true }}>Be Part of our Team</motion.h2>
      </div>
      </div>
    </div>
  );
};

export default Bannerbeoneofus;
