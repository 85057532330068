import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import './scroll.css';

const ScrollFlesh = () => {
	const [scrollPosition, setScrollPosition] = useState(0);
	const [pathLength, setPathLength] = useState(0);
	const [scale, setScale] = useState(1);
	const [arrowVisible, setArrowVisible] = useState(true);

	const pathRef = useRef(null);
	const textList = [
		"INNOVATION",
		"EXPERTISE",
		"CREATIVITY",
		"FLEXIBILITY",
		"ADAPTABILITY",
	];
	const textPositions = useRef([]);

	const [textVisibility, setTextVisibility] = useState(
		Array(textList.length).fill(false)
	);

	useEffect(() => {
		const path = pathRef.current;
		if (path) {
			const totalLength = path.getTotalLength();
			setPathLength(totalLength);

			const handleScroll = () => {
				const scrollY = window.scrollY;
				setScrollPosition(scrollY);
			};

			window.addEventListener("scroll", handleScroll);
			return () => {
				window.removeEventListener("scroll", handleScroll);
			};
		}
	}, []);

	useEffect(() => {
		const percentage =
			(scrollPosition / (document.body.scrollHeight - window.innerHeight / 4)) *
			100;

		const newScale = 1 + percentage / 100;
		setScale(newScale);

		const arrowY = textPositions.current[0]?.y || 0;
		const visibleIndexes = textPositions.current.map((textPoint) => {
			const distanceText = Math.abs(arrowY - textPoint.y);
			return distanceText < 100;
		});


		setTextVisibility(visibleIndexes);
	}, [scrollPosition]);

	useEffect(() => {
		const handleResize = () => {
			const path = pathRef.current;
			if (path) {
				const totalLength = path.getTotalLength();
				setPathLength(totalLength);
			}
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	let point = { x: 0, y: 0 };
	let angle = 0;
	if (pathRef.current) {
		const percentage =
			(scrollPosition / (document.body.scrollHeight - window.innerHeight)) *
			250;
		point = pathRef.current.getPointAtLength((pathLength * percentage) / 100);
		const nextPoint = pathRef.current.getPointAtLength(
			(pathLength * (percentage + 0.1)) / 100
		);
		angle =
			Math.atan2(nextPoint.y - point.y, nextPoint.x - point.x) *
			(180 / Math.PI);
	}

	const ballStyle = {
		position: "absolute",
		left: `${point.x}px`,
		top: `${point.y}px`,
		width: `${ 4 * scale}vw`,
		height: `${ 4 * scale}vw`,
		backgroundColor: "red",
		clipPath: "polygon(0% 0%, 100% 50%, 0% 100%)",
		transform: `translate(-50%, -50%) rotate(${angle}deg)`,
		boxShadow: "0 0 10px rgba(0,0,0,0.3)",
		display: `${arrowVisible ? 'block' : 'none'}`,
	};

	const offsetRight = 0;
	const offsetLeft = -0;

	const textElements = textList.map((text, index) => {
		if (!pathRef.current) return null;
		const textPercentage = (index / (textList.length - 1)) * 200;
		const textPoint = pathRef.current.getPointAtLength(
			(pathLength * textPercentage) / 200
		);
		const distance = Math.sqrt(
			Math.pow(point.x - textPoint.x, 2) + Math.pow(point.y - textPoint.y, 2)
		);

		textPositions.current[index] = textPoint;
		const isVisibleText = textVisibility[index];
		const isVisible =
			distance < 150 ||
			((index === 0 || index === textList.length - 1) && distance < 200);

		const isOnRightSide = index % 2 === 0;

		const adjustedX =
			isOnRightSide
				? textPoint.x + offsetRight + 70
				: textPoint.x + offsetLeft + 70;
		const adjustedTop =
			index === 0
				? Math.max(textPoint.y, 20) // Ensure 'INNOVATION' is visible
				: index === textList.length - 1
					? Math.min(textPoint.y, 2000) // Ensure 'ADAPTABILITY' is visible
					: textPoint.y - 30;

		return (
			<motion.div
				key={index}
				className={"textSyle"}
				style={{
					position: "absolute",
					left: `${adjustedX}px`,
					top: `${adjustedTop}px`,
					fontSize: "4vw", // Adjust font size for responsiveness
					fontWeight: "700",
					textAlign: "center",
					color: "black",
					opacity: isVisibleText ? 1 : 0,
				}}
				initial={{
					opacity: 1,
					x: 0, // initial x position
				}}
				animate={{
					opacity: isVisible ? 1 : 0,
					x: -50, // translateX to 100%
				}}
				transition={{
					ease: [0.08, 0.65, 0.53, 0.96],
					duration: 2.6
				}}
			>
				{text}
			</motion.div>
		);
	});

	return (
		<div
			style={{
				minHeight: "30rem",
				height: "2100px",
				position: "relative",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				overflow: "hidden",
				backgroundColor:"#e6e6e6"
			}}
		>
			<div className="snow">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1536"
					 preserveAspectRatio="xMidYMax slice">
					<g fill="RGB(122, 62, 128)" fill-opacity=".15" transform="translate(55 42)">
						<g id="snow-bottom-layer">
							<ellipse cx="6" cy="1009.5" rx="6" ry="5.5"/>
							<ellipse cx="138" cy="1110.5" rx="6" ry="5.5"/>
							<ellipse cx="398" cy="1055.5" rx="6" ry="5.5"/>
							<ellipse cx="719" cy="1284.5" rx="6" ry="5.5"/>
							<ellipse cx="760" cy="1155.5" rx="6" ry="5.5"/>
							<ellipse cx="635" cy="1459.5" rx="6" ry="5.5"/>
							<ellipse cx="478" cy="1335.5" rx="6" ry="5.5"/>
							<ellipse cx="322" cy="1414.5" rx="6" ry="5.5"/>
							<ellipse cx="247" cy="1234.5" rx="6" ry="5.5"/>
							<ellipse cx="154" cy="1425.5" rx="6" ry="5.5"/>
							<ellipse cx="731" cy="773.5" rx="6" ry="5.5"/>
							<ellipse cx="599" cy="874.5" rx="6" ry="5.5"/>
							<ellipse cx="339" cy="819.5" rx="6" ry="5.5"/>
							<ellipse cx="239" cy="1004.5" rx="6" ry="5.5"/>
							<ellipse cx="113" cy="863.5" rx="6" ry="5.5"/>
							<ellipse cx="102" cy="1223.5" rx="6" ry="5.5"/>
							<ellipse cx="395" cy="1155.5" rx="6" ry="5.5"/>
							<ellipse cx="826" cy="943.5" rx="6" ry="5.5"/>
							<ellipse cx="626" cy="1054.5" rx="6" ry="5.5"/>
							<ellipse cx="887" cy="1366.5" rx="6" ry="5.5"/>
							<ellipse cx="6" cy="241.5" rx="6" ry="5.5"/>
							<ellipse cx="138" cy="342.5" rx="6" ry="5.5"/>
							<ellipse cx="398" cy="287.5" rx="6" ry="5.5"/>
							<ellipse cx="719" cy="516.5" rx="6" ry="5.5"/>
							<ellipse cx="760" cy="387.5" rx="6" ry="5.5"/>
							<ellipse cx="635" cy="691.5" rx="6" ry="5.5"/>
							<ellipse cx="478" cy="567.5" rx="6" ry="5.5"/>
							<ellipse cx="322" cy="646.5" rx="6" ry="5.5"/>
							<ellipse cx="247" cy="466.5" rx="6" ry="5.5"/>
							<ellipse cx="154" cy="657.5" rx="6" ry="5.5"/>
							<ellipse cx="731" cy="5.5" rx="6" ry="5.5"/>
							<ellipse cx="599" cy="106.5" rx="6" ry="5.5"/>
							<ellipse cx="339" cy="51.5" rx="6" ry="5.5"/>
							<ellipse cx="239" cy="236.5" rx="6" ry="5.5"/>
							<ellipse cx="113" cy="95.5" rx="6" ry="5.5"/>
							<ellipse cx="102" cy="455.5" rx="6" ry="5.5"/>
							<ellipse cx="395" cy="387.5" rx="6" ry="5.5"/>
							<ellipse cx="826" cy="175.5" rx="6" ry="5.5"/>
							<ellipse cx="626" cy="286.5" rx="6" ry="5.5"/>
							<ellipse cx="887" cy="598.5" rx="6" ry="5.5"/>
						</g>
					</g>
					<g fill="#FFF" fill-opacity=".3" transform="translate(65 63)">
						<g id="snow-top-layer">
							<circle cx="8" cy="776" r="8"/>
							<circle cx="189" cy="925" r="8"/>
							<circle cx="548" cy="844" r="8"/>
							<circle cx="685" cy="1115" r="8"/>
							<circle cx="858" cy="909" r="8"/>
							<circle cx="874" cy="1438" r="8" transform="rotate(180 874 1438)"/>
							<circle cx="657" cy="1256" r="8" transform="rotate(180 657 1256)"/>
							<circle cx="443" cy="1372" r="8" transform="rotate(180 443 1372)"/>
							<circle cx="339" cy="1107" r="8" transform="rotate(180 339 1107)"/>
							<circle cx="24" cy="1305" r="8" transform="rotate(180 24 1305)"/>
							<circle cx="8" cy="8" r="8"/>
							<circle cx="189" cy="157" r="8"/>
							<circle cx="548" cy="76" r="8"/>
							<circle cx="685" cy="347" r="8"/>
							<circle cx="858" cy="141" r="8"/>
							<circle cx="874" cy="670" r="8" transform="rotate(180 874 670)"/>
							<circle cx="657" cy="488" r="8" transform="rotate(180 657 488)"/>
							<circle cx="443" cy="604" r="8" transform="rotate(180 443 604)"/>
							<circle cx="339" cy="339" r="8" transform="rotate(180 339 339)"/>
							<circle cx="24" cy="537" r="8" transform="rotate(180 24 537)"/>
						</g>
					</g>
				</svg>
			</div>


			<div
				style={{
					position: "relative",
					top: 0,
					height: "100%",
				}}
			>
				<svg width="100%" height="100%" fill="none" style={{position: "relative", top: 0}}>
					<path d="M381 2.5C178.167 52.3333 113.9 268.1 340.5 732.5C794.9 1196.9 304.167 1765 2 1991"
						  id="motion-path"
						  ref={pathRef}/>
				</svg>


				<svg width="275" height="2185" viewBox="0 0 275 2185" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M137.5 1C34.6666 150.167 -109.3 571 137.5 1061C384.3 1551 240.333 2014.17 137.5 2184.5"
						  id="motion-path"
						  ref={pathRef}/>
				</svg>


				<div style={ballStyle} className={"ballStyle"}></div>
				{textElements}
			</div>
		</div>
	);
};

export default ScrollFlesh;
