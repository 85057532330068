import React from "react";
import "./innovation.css";
import adabtaptability from "../img/adabtaptability_animation.gif";
import adabtaptabilityIcon from "../img/adabtaptability_icon.png";
import { motion } from "framer-motion";

const Adabtaptability = () => {
  return (
    <div className="innovation">
      <div className="innovation_img">
        <motion.img
          src={adabtaptability}
          alt="innovation"
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          viewport={{ once: true }}
        />
      </div>
      <div className="innovation_title">
        <motion.h1
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          viewport={{ once: true }}
        >
          ADAPTABILITY
        </motion.h1>
      </div>
      <div className="innovation_text">
        <motion.img
          src={adabtaptabilityIcon}
          alt="innovationIcon"
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.p
          initial={{
            opacity: 0,
            x: 30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          Adaptability in the workplace is a vital trait that enables both
          individuals and organizations to thrive in an ever-changing
          environment. It involves the willingness to learn, embrace change, and
          modify one’s approach to meet new challenges. For us, adaptability
          enhances our value, leadership qualities, and ability to tackle
          adversity. For Mammut, it fosters resilience, promotes innovation, and
          ensures sustained success.
        </motion.p>
      </div>
    </div>
  );
};

export default Adabtaptability;
