import React, { useState , useEffect} from "react";
import "./components/be.css";
import arrow10 from "./img/arrow10.png" ;
import Recruitmentform from "./components/Recruitmentform" ; 
import Internshipform from "./components/Internshipform" ; 
import Bannerbeoneofus from "./components/Bannerbeoneofus";
import Partnershipform from "./components/Partnershipform";
import { Footer } from "../home/Footer";
import {motion} from "framer-motion" ; 
const Beoneofus = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    
  
  };
  useEffect(() => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, []);
  return (
    <div id="bannerbeoneofus">
      <Bannerbeoneofus />
      <div className="beoneofus">
        <div>
         
          <div className="formular_list">
            <motion.button 
            initial={{
              opacity: 0,
              y: -50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.6,
              },
            }}
            viewport={{ once: true }}
              onClick={() => handleTabClick("tab1")}
              className={
                activeTab === "tab1" ? "active navbar-link" : "navbar-link"
              }
            >
              <div className="contactemail">
                <div className="line">
                  <h2>Fill the recruitment form</h2>
                  <div className="arrow_branding_project">
                    <h1>Recruitment Form</h1>
                    {/* <img src={arrow10} alt="arrow" /> */}
                  </div>
                </div>
              </div>
            </motion.button>
            <motion.button 
            initial={{
              opacity: 0,
              y: -50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.8,
              },
            }}
            viewport={{ once: true }}
              onClick={() => handleTabClick("tab2")}
              className={
                activeTab === "tab2" ? "active navbar-link" : "navbar-link"
              }
            >
              <div ><div className="contactemail">
                <div className="line">
                  <h2>Fill the internship form</h2>
                  <div className="arrow_branding_project">
                    <h1>Internship Form</h1>
                    {/* <img src={arrow10} alt="arrow" /> */}
                  </div>
                </div>
              </div></div>
            </motion.button>
            <motion.button 
            initial={{
              opacity: 0,
              y: -50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1,
              },
            }}
            viewport={{ once: true }}
              onClick={() => handleTabClick("tab3")}
              className={
                activeTab === "tab3" ? "active navbar-link" : "navbar-link"
              }
            >
              <div ><div className="contactemail">
                <div className="line">
                  <h2>Fill the Partnership form</h2>
                  <div className="arrow_branding_project">
                    <h1>Partnership Form</h1>
                    {/* <img src={arrow10} alt="arrow" /> */}
                  </div>
                </div>
              </div></div>
            </motion.button>
          </div>

          {/* Content for each tab */}
          <div>
            {activeTab === "tab1" && <div><Recruitmentform /></div>}
            {activeTab === "tab2" && <div><Internshipform /></div>}
            {activeTab === "tab3" && <div><Partnershipform /></div>}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Beoneofus;
