import React from "react";

import circle from "../img/circle.png" ;
import circle1 from "../img/circle1.png" ; 
import elearning from "../img/elearning.png"  ; 
import "./dev.css";
import {motion} from "framer-motion" ; 
const Elearning = () => {
    return (
        
<div className="bannersol">

        
        <div className="bannersoltext pe-5 me-5" id="bannersoltext">
            <motion.h2 className="pe-5 me-5" 
            initial={{
                opacity: 0,
                x: -15,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.6,
                },
              }}
              
              viewport={{ once: true }}>E-learning courses to learn</motion.h2>
            <motion.h1 className="pe-5 me-5" 
            initial={{
                opacity: 0,
                x: -15,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.8,
                },
              }}
              
              viewport={{ once: true }}> your growth <span>Goals</span></motion.h1>
        </div>
  <motion.img src={elearning} className="end-0 opacity-100" 
  initial={{
    opacity: 0,
    y: 15,
  }}
  whileInView={{
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  }}
  
  viewport={{ once: true }}/>
      
        </div>
        
    );
  };
  
  export default Elearning;