import React, { useState } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import "./contactbanner.css";

const Banner = () => {
    const [object, setObject] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post("https://vps-b1796450.vps.ovh.net/send-contact-email", {
                object,
                email,
                message,
            });

            if (response.status === 200) {
                alert('Email sent successfully!');
            } else {
                alert('Failed to send email. Please try again later.');
            }
        } catch (error) {
            console.error("Error sending email:", error);
            alert('Failed to send email. Please try again later.');
        }

        // Clear form fields
        setObject("");
        setEmail("");
        setMessage("");
    };

    return (
        <div className="contactbanner">
            <motion.h1
                initial={{
                    opacity: 0,
                    x: -50,
                }}
                whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                        duration: 0.6,
                    },
                }}
                viewport={{ once: true }}
            >
                Your Business <span>Elevated</span>,
            </motion.h1>
            <motion.h2
                initial={{
                    opacity: 0,
                    x: -50,
                }}
                whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                        duration: 0.8,
                    },
                }}
                viewport={{ once: true }}
            >
                One email away
            </motion.h2>
            <div className="contactForm">
                <form onSubmit={handleSubmit}>
                    <h2>Send Message</h2>
                    <div className="inputBox">
                        <input
                            type="text"
                            name="object"
                            value={object}
                            onChange={(e) => setObject(e.target.value)}
                            required
                        />
                        <span>Subject</span>
                    </div>
                    <div className="inputBox">
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <span>Your E-mail</span>
                    </div>
                    <div className="inputBox">
            <textarea
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
            />
                        <span>Type your Message...</span>
                    </div>
                    <div className="inputBox">
                        <input type="submit" value="Send" />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Banner;
