import React from "react";
import market from "../img/market.png";
import { motion } from "framer-motion";

import "./dev.css";
const Marketing = () => {
  return (
    <div className="bannersol">
      <div className="bannersoltext pe-5" id="bannersoltext">
        <motion.h2
          initial={{
            opacity: 0,
            x: 15,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          viewport={{ once: true }}
        >
          Marketing to improve
        </motion.h2>
        <motion.h1
          initial={{
            opacity: 0,
            x: 15,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          viewport={{ once: true }}
        >
          {" "}
          your growth <span>Goals</span>
        </motion.h1>
      </div>
      <motion.img
        src={market}
        className="start-0 opacity-100"
        initial={{
          opacity: 0,
          x: -15,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.6,
          },
        }}
        viewport={{ once: true }}
      />
    </div>
  );
};

export default Marketing;
