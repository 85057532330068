import { motion } from "framer-motion";
import { Link } from "react-router-dom";

export const Contact = () => {
  return (
    <div className="newsletter-bx">
      <div className="foot">
        <motion.h2
        initial={{
          opacity: 0,
          x: -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.6,
          },
        }}
        
        viewport={{ once: true }}>Let’s create your growth </motion.h2>
        <motion.h1 initial={{
                    opacity: 0,
                    x: -50,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.8,
                    },
                  }}
                  
                  viewport={{ once: true }}>ERA</motion.h1>
        <div className="discover ">
          <motion.h3 
          initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          
          viewport={{ once: true }}>contact us </motion.h3><Link to="/contact#contactBanner">
          <motion.div className="arrow1 "
          initial={{
            opacity: 0,
            x: 50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1.2,
            },
          }}
          
          viewport={{ once: true }}
          >
            
            <svg height="100" width="90">
              <polygon points="55,25 65,25 60,10" fill="#CC5803" />

              <rect x="58" y="25" width="4" height="45" fill="#CC5803" />
            </svg>
            
            <div className="circle1"></div>
          </motion.div></Link>
        </div>
      </div>
    </div>
  );
};
