import React , {useEffect} from "react";
import brand from "../img/branding.png";
import graph from "../img/graphic.png";
import motionimg from "../img/motion.png";
import devolep from "../img/devolep.png";
import Elearning from "./Elearning";
import uiux from "../img/uiux.png";
import Marketing from "./Marketing";
import digital from "../img/digital.png";
import seo from "../img/seo.png";
import sm from "../img/sm.png";
import "./branding.css";
import "./graphic.css";
import { motion } from "framer-motion";

import Dev from "./dev";
import Approach from "./Approach";
import { Footer } from "../../home/Footer";



const Branding = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <div >
      <div className="position-relative">
        <div className="branding">
          <div className="brand" id="photo_right">
            <div className="branding-text" >
              <motion.h1
                initial={{
                  opacity: 0,
                  x: -15,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.6,
                  },
                }}
                viewport={{ once: true }}
              >
                Branding & Identity
              </motion.h1>
              <motion.div
                className="p"
                initial={{
                  opacity: 0,
                  x: -15,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.7,
                  },
                }}
                viewport={{ once: true }}
              >
                <p>
                  Mammut Web Solutions crafts distinctive and memorable brand
                  imagery, employing a streamlined aesthetic to optimize
                  investment value.
                </p>
              </motion.div>
              <div>
                <ul>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Brand Strategy & Experience
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.9,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Trends & Insights
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Guidelines & Systems{" "}
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.1,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Content Strategy
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.2,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Identity Design
                  </motion.li>
                </ul>
                <div className="go">
                  <motion.h2
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.3,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Branding Projects
                  </motion.h2>
                  <motion.div
                    className="arrowBranding"
                    initial={{
                      opacity: 0,
                      y: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 1.4,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <div className="arrow">
                      <svg height="100" width="90">
                        <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                        <rect
                          x="58"
                          y="25"
                          width="4"
                          height="165"
                          fill="#CC5803"
                        />
                      </svg>

                      <div className="circle"></div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
            <motion.div
              className="branding-img"
              initial={{
                opacity: 0,
                x: 15,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.6,
                },
              }}
              viewport={{ once: true }}
            >
              <img src={brand} alt="branding" />
            </motion.div>
          </div>
          <div className="graphic" id="photo_left">
            <motion.div
              className="graphic-img" id="graphic-img"
              initial={{
                opacity: 0,
                x: -15,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.6,
                },
              }}
              viewport={{ once: true }}
            >
              <img src={graph} alt="alalal" />
            </motion.div>
            <div className="graphic-text" id="graphic-text">
              <motion.h1
                initial={{
                  opacity: 0,
                  x: 15,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.6,
                  },
                }}
                viewport={{ once: true }}
              >
                Graphic Design
              </motion.h1>
              <motion.div
                className="p"
                initial={{
                  opacity: 0,
                  x: 15,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.7,
                  },
                }}
                viewport={{ once: true }}
              >
                <p>
                  Our company offers a comprehensive portfolio of graphic design
                  services, expertly engineered to enhance brand visibility and
                  presentation.
                </p>
              </motion.div>
              <div>
                <ul>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Content Creation{" "}
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Flyers & Webinars
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.9,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Infographics
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Portfolio Design{" "}
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.1,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Identity Design
                  </motion.li>
                </ul>
                <div className="go">
                  <motion.h2
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.2,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Design Projects
                  </motion.h2>
                  <motion.div
                    className="arrowgraphic"
                    initial={{
                      opacity: 0,
                      y: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.6,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <div className="arrow">
                      <svg height="100" width="90">
                        <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                        <rect
                          x="58"
                          y="25"
                          width="4"
                          height="165"
                          fill="#CC5803"
                        />
                      </svg>

                      <div className="circle"></div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <div className="graphic" id="photo_right">
            <div className="graphic-text" id="graphic-text">
              <motion.h1
                initial={{
                  opacity: 0,
                  x: -15,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.6,
                  },
                }}
                viewport={{ once: true }}
              >
                Motion Design
              </motion.h1>
              <motion.div
                className="p"
                initial={{
                  opacity: 0,
                  x: -15,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.7,
                  },
                }}
                viewport={{ once: true }}
              >
                <p>
                  We use the latest tools and software to create
                  high-quality motion graphics. We provide a
                  comprehensive 2D & 3D animation design service, including
                  composition and sound design as well as animated graphic
                  creation.
                </p>
              </motion.div>
              <div>
                <ul>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    2D & 3D Motion Design
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.9,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    3D Animation
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Interactive Design
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.1,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Modeling
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.2,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Mockups
                  </motion.li>
                </ul>
                <div className="go">
                  <motion.h2
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.3,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Motion Projects
                  </motion.h2>
                  <motion.div
                    className="arrowgraphic"
                    initial={{
                      opacity: 0,
                      y: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 1.4,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <div className="arrow">
                      <svg height="100" width="90">
                        <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                        <rect
                          x="58"
                          y="25"
                          width="4"
                          height="165"
                          fill="#CC5803"
                        />
                      </svg>

                      <div className="circle"></div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
            <motion.div
              className="graphic-img" id="graphic-img"
              initial={{
                opacity: 0,
                x: 15,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.6,
                },
              }}
              viewport={{ once: true }}
            >
              <img src={motionimg} alt="alalal" />
            </motion.div>
          </div>
          <div className="graphic" id="photo_left">
            <motion.div
              className="graphic-img" id="graphic-img" 
              initial={{
                opacity: 0,
                x: -15,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 0.6,
                },
              }}
              viewport={{ once: true }}
            >
              <img src={uiux} alt="alalal" />
            </motion.div>
            <div className="graphic-text" id="graphic-text">
              <motion.h1
                initial={{
                  opacity: 0,
                  x: 15,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.6,
                  },
                }}
                viewport={{ once: true }}
              >
                UI & UX Design
              </motion.h1>
              <motion.div
                className="p"
                initial={{
                  opacity: 0,
                  x: 15,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 0.7,
                  },
                }}
                viewport={{ once: true }}
              >
                <p>
                  Mammut Web Solutions excels in providing state-of-the-art
                  UI/UX design services, ensuring that outstanding final
                  products are delivered on schedule.
                </p>
              </motion.div>
              <div>
                <ul>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.8,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Web Design
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.9,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Mobile Application Design
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Portfolio Design{" "}
                  </motion.li>
                  <motion.li
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.1,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Identity Design
                  </motion.li>
                </ul>
                <div className="go">
                  <motion.h2
                    initial={{
                      opacity: 0,
                      x: -15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 1.2,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Web Projects
                  </motion.h2>
                  <motion.div
                    className="arrowgraphic"
                    initial={{
                      opacity: 0,
                      y: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 1.3,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <div className="arrow">
                      <svg height="100" width="90">
                        <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                        <rect
                          x="58"
                          y="25"
                          width="4"
                          height="165"
                          fill="#CC5803"
                        />
                      </svg>

                      <div className="circle"></div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <div id="web"></div>
          <Dev/>
          <div className="position-relative">
            <div className="branding">
              <div className="brand" id="photo_left">
                <motion.div
                  className="branding-img"
                  initial={{
                    opacity: 0,
                    x: -15,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 0.6,
                    },
                  }}
                  viewport={{ once: true }}
                >
                  <img src={devolep} />
                </motion.div>
                <div className="branding-text">
                  <motion.h1
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.6,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    Web & Mobile Development
                  </motion.h1>
                  <motion.div
                    className="p"
                    initial={{
                      opacity: 0,
                      x: 15,
                    }}
                    whileInView={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: 0.7,
                      },
                    }}
                    viewport={{ once: true }}
                  >
                    <p>
                      Our web development team develops adaptive and indigenous
                      technology.
                    </p>
                  </motion.div>
                  <div>
                    <ul>
                      <motion.li
                        initial={{
                          opacity: 0,
                          x: 15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 0.8,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        Digital Strategy
                      </motion.li>
                      <motion.li
                        initial={{
                          opacity: 0,
                          x: 15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 0.9,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        Functional Prototyping
                      </motion.li>
                      <motion.li
                        initial={{
                          opacity: 0,
                          x: 15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 1,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        UX & UI Design{" "}
                      </motion.li>
                      <motion.li
                        initial={{
                          opacity: 0,
                          x: 15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 1.1,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        Web & App Development
                      </motion.li>
                      <motion.li
                        initial={{
                          opacity: 0,
                          x: 15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 1.2,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        Digital Activation
                      </motion.li>
                    </ul>
                    <div className="go">
                      <motion.h2
                        initial={{
                          opacity: 0,
                          x: -15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 1.3,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        Development Projects
                      </motion.h2>
                      <motion.div
                        className="arrowBranding"
                        initial={{
                          opacity: 0,
                          y: 15,
                        }}
                        whileInView={{
                          opacity: 1,
                          y: 0,
                          transition: {
                            duration: 1.3,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        <div className="arrow">
                          <svg height="100" width="90">
                            <polygon
                              points="55,25 65,25 60,10"
                              fill="#CC5803"
                            />

                            <rect
                              x="58"
                              y="25"
                              width="4"
                              height="165"
                              fill="#CC5803"
                            />
                          </svg>

                          <div className="circle"></div>
                        </div>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </div>
              <Marketing />
              <div className="position-relative">
                <div className="branding">
                  <div className="brand" id="photo_right">
                    <div className="branding-text">
                      <motion.h1
                        initial={{
                          opacity: 0,
                          x: -15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 0.6,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        SEO Marketing
                      </motion.h1>
                      <motion.div
                        className="p"
                        initial={{
                          opacity: 0,
                          x: -15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 0.7,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        <p>
                          Our expert team is at your disposal, offering advanced
                          strategies to enhance your digital content’s search
                          engine presence, elevate your position in search
                          results, and refine your overall online effectiveness.
                        </p>
                      </motion.div>
                      <div>
                        <ul>
                          <motion.li
                            initial={{
                              opacity: 0,
                              x: -15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 0.8,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Google SEO
                          </motion.li>
                          <motion.li
                            initial={{
                              opacity: 0,
                              x: -15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 0.9,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Web Optimization
                          </motion.li>
                          <motion.li
                            initial={{
                              opacity: 0,
                              x: -15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 1,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Digital Growth Strategy
                          </motion.li>
                          <motion.li
                            initial={{
                              opacity: 0,
                              x: -15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 1.1,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Digital Guidelines
                          </motion.li>
                        </ul>
                        <div className="go">
                          <motion.h2
                            initial={{
                              opacity: 0,
                              x: -15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 1.2,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            SEO Projects
                          </motion.h2>
                          <motion.div
                            className="arrowBranding"
                            initial={{
                              opacity: 0,
                              y: 15,
                            }}
                            whileInView={{
                              opacity: 1,
                              y: 0,
                              transition: {
                                duration: 1.2,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            <div className="arrow">
                              <svg height="100" width="90">
                                <polygon
                                  points="55,25 65,25 60,10"
                                  fill="#CC5803"
                                />

                                <rect
                                  x="58"
                                  y="25"
                                  width="4"
                                  height="165"
                                  fill="#CC5803"
                                />
                              </svg>

                              <div className="circle"></div>
                            </div>
                          </motion.div>
                        </div>
                      </div>
                    </div>
                    <motion.div
                      className="branding-img"
                      initial={{
                        opacity: 0,
                        x: 15,
                      }}
                      whileInView={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 0.6,
                        },
                      }}
                      viewport={{ once: true }}
                    >
                      <img src={seo} alt="seo" />
                    </motion.div>
                  </div>
                  <div className="graphic" id="photo_left">
                    <motion.div
                      className="graphic-img" id="graphic-img"
                      initial={{
                        opacity: 0,
                        x: -15,
                      }}
                      whileInView={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          duration: 0.6,
                        },
                      }}
                      viewport={{ once: true }}
                    >
                      <img src={sm} alt="social media" />
                    </motion.div>
                    <div className="graphic-text" id="graphic-text" >
                      <motion.h1
                        initial={{
                          opacity: 0,
                          x: 15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 0.6,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        Social Media Management
                      </motion.h1>
                      <motion.div
                        className="p"
                        initial={{
                          opacity: 0,
                          x: 15,
                        }}
                        whileInView={{
                          opacity: 1,
                          x: 0,
                          transition: {
                            duration: 0.7,
                          },
                        }}
                        viewport={{ once: true }}
                      >
                        <p>
                          Mammut Web Solutions offers analysis and reporting
                          services that empower clients to evaluate the
                          magnitude of their digital marketing endeavors.
                        </p>
                      </motion.div>
                      <div>
                        <ul>
                          <motion.li
                            initial={{
                              opacity: 0,
                              x: 15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 0.8,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Trends & Insights
                          </motion.li>
                          <motion.li
                            initial={{
                              opacity: 0,
                              x: 15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 0.9,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Content Strategy
                          </motion.li>
                          <motion.li
                            initial={{
                              opacity: 0,
                              x: 15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 1,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Marketing Plans
                          </motion.li>
                          <motion.li
                            initial={{
                              opacity: 0,
                              x: 15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 1.1,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Marketing Strategies
                          </motion.li>
                          <motion.li
                            initial={{
                              opacity: 0,
                              x: 15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 1.2,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Community Management
                          </motion.li>
                        </ul>
                        <div className="go">
                          <motion.h2
                            initial={{
                              opacity: 0,
                              x: -15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 1.3,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Social Media Projects
                          </motion.h2>
                          <motion.div
                            className="arrowgraphic"
                            initial={{
                              opacity: 0,
                              y: 15,
                            }}
                            whileInView={{
                              opacity: 1,
                              y: 0,
                              transition: {
                                duration: 1.3,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            <div className="arrow">
                              <svg height="100" width="90">
                                <polygon
                                  points="55,25 65,25 60,10"
                                  fill="#CC5803"
                                />

                                <rect
                                  x="58"
                                  y="25"
                                  width="4"
                                  height="165"
                                  fill="#CC5803"
                                />
                              </svg>

                              <div className="circle"></div>
                            </div>
                          </motion.div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Elearning />
                  <div className="position-relative">
                    <div className="branding">
                      <div className="brand" id="photo_left">
                        <motion.div
                          className="branding-img"
                          initial={{
                            opacity: 0,
                            x: -15,
                          }}
                          whileInView={{
                            opacity: 1,
                            x: 0,
                            transition: {
                              duration: 0.6,
                            },
                          }}
                          viewport={{ once: true }}
                        >
                          <img src={digital} alt="digital" />
                        </motion.div>
                        <div className="branding-text">
                          <motion.h1
                            initial={{
                              opacity: 0,
                              x: 15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 0.6,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Digital Learning
                          </motion.h1>
                          <motion.div
                            className="p"
                            initial={{
                              opacity: 0,
                              x: 15,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 0.7,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            <p>
                              Innovative digital learning experiences and
                              tailored projects are crafted to foster rapid and
                              impactful online education, attentively
                              considering the unique requirements and
                              foundational knowledge of each client.
                            </p>
                          </motion.div>
                          <div>
                            <ul>
                              <motion.li
                                initial={{
                                  opacity: 0,
                                  x: 15,
                                }}
                                whileInView={{
                                  opacity: 1,
                                  x: 0,
                                  transition: {
                                    duration: 0.8,
                                  },
                                }}
                                viewport={{ once: true }}
                              >
                                E-learning courses
                              </motion.li>
                              <motion.li
                                initial={{
                                  opacity: 0,
                                  x: 15,
                                }}
                                whileInView={{
                                  opacity: 1,
                                  x: 0,
                                  transition: {
                                    duration: 0.9,
                                  },
                                }}
                                viewport={{ once: true }}
                              >
                                Presentation Design
                              </motion.li>
                              <motion.li
                                initial={{
                                  opacity: 0,
                                  x: 15,
                                }}
                                whileInView={{
                                  opacity: 1,
                                  x: 0,
                                  transition: {
                                    duration: 1,
                                  },
                                }}
                                viewport={{ once: true }}
                              >
                                E-learning Developing
                              </motion.li>
                              <motion.li
                                initial={{
                                  opacity: 0,
                                  x: 15,
                                }}
                                whileInView={{
                                  opacity: 1,
                                  x: 0,
                                  transition: {
                                    duration: 1.1,
                                  },
                                }}
                                viewport={{ once: true }}
                              >
                                Educational Illustrations
                              </motion.li>
                              <motion.li
                                initial={{
                                  opacity: 0,
                                  x: 15,
                                }}
                                whileInView={{
                                  opacity: 1,
                                  x: 0,
                                  transition: {
                                    duration: 1.2,
                                  },
                                }}
                                viewport={{ once: true }}
                              >
                                Scripts Simplifying
                              </motion.li>
                            </ul>
                            <div className="go">
                              <motion.h2
                                initial={{
                                  opacity: 0,
                                  x: -15,
                                }}
                                whileInView={{
                                  opacity: 1,
                                  x: 0,
                                  transition: {
                                    duration: 1.3,
                                  },
                                }}
                                viewport={{ once: true }}
                              >
                                E-learning Projects
                              </motion.h2>
                              <motion.div
                                className="arrowBranding"
                                initial={{
                                  opacity: 0,
                                  y: 15,
                                }}
                                whileInView={{
                                  opacity: 1,
                                  y: 0,
                                  transition: {
                                    duration: 0.6,
                                  },
                                }}
                                viewport={{ once: true }}
                              >
                                <div className="arrow">
                                  <svg height="100" width="90">
                                    <polygon
                                      points="55,25 65,25 60,10"
                                      fill="#CC5803"
                                    />

                                    <rect
                                      x="58"
                                      y="25"
                                      width="4"
                                      height="165"
                                      fill="#CC5803"
                                    />
                                  </svg>

                                  <div className="circle"></div>
                                </div>
                              </motion.div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Approach />
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branding;
