import send from "./img/send_animation.gif" ; 
import arrow10 from "./img/Arrow10 copy.png" ; 
import "./success.css"
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/'); // Navigates to the home page
    };
  return (
    <div className="success">
        <div className="success_photo" >
            <img src={send} alt="send" />
        </div>
        <div className="success_title"> 
            <h1>Your <span>SUBMISSION</span></h1>
        </div>
        <div className="success_title">
            <h1>has been successfully sent</h1>
        </div>
        <div className="success_text">
            <p>THANKS FOR YOUR COLLABORATION, WE WILL CONTACT YOU SOON...</p>
        </div>
        <div className="success_button" onClick={handleButtonClick}>
            <h2>Back</h2>
            <img src={arrow10} alt="arrow5" />
        </div>
    </div>
  );
};

export default Success;