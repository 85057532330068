import React from "react";
import "./innovation.css";
import expertise from "../img/expertise_animation.gif";
import expertiseIcon from "../img/expertise_icon.png";
import { motion } from "framer-motion";

const Expertise = () => {
  return (
    <div className="innovation">
      <div className="innovation_img">
        <motion.img
          src={expertise}
          alt="innovation"
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          viewport={{ once: true }}
        />
      </div>
      <div className="innovation_title">
        <motion.h1
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          viewport={{ once: true }}
        >
          EXPERTISE
        </motion.h1>
      </div>
      <div className="innovation_text">
        <motion.img
          src={expertiseIcon}
          alt="innovationIcon"
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.p
          initial={{
            opacity: 0,
            x: 30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          Expertise refers to the knowledge and skills that distinguish top
          performers from novices and less proficient individuals. We
          encompasses a high level of proficiency in content creation domain. We
          showcased our expertise on marketing, web development and graphic
          design, We demonstrate our qualifications and standed out as a
          qualified company.
        </motion.p>
      </div>
    </div>
  );
};

export default Expertise;
