import React ,{useEffect} from "react";
import BannerAdvantages from "./components/BannerAdvantages"
import HowMammutWorks from "./components/HowMammutWorks" ;
import Innovation from "./components/Innovation";
import Expertise  from "./components/Expertise";
import Flexibility from "./components/Flexibility";
import Creativity from "./components/Creativity";
import Adabtaptability from "./components/Adaptability";
import { Footer } from "../home/Footer";

const OurAdvantages = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <div id="BannerAdvantages">
      
        <BannerAdvantages  />
        <HowMammutWorks />
        <Innovation />
        <Expertise />
        <Creativity />
        <Flexibility />
        <Adabtaptability />
        <br></br><br></br><br></br>
        <Footer />
    </div>
  );
};

export default OurAdvantages;
