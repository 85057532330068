import "./standards.css";
import ImgINNOVATION from "../assets/img/INNOVATION.jpg";
import ImgEXPERTISE from "../assets/img/EXPERTISE.jpg";
import ImgCREATIVITY from "../assets/img/CREATIVITY.jpg";
import ImgFLEXIBILITY from "../assets/img/FLEXIBILITY.jpg";
import ImgADAPTABILITY from "../assets/img/ADAPTABILITY.jpg";

import { useRef } from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue
} from "framer-motion";

function useParallax(value: MotionValue<number>, distance: number) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

function Image({ id , src }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, 50);

  return (
    <section>
      <div ref={ref}>
        <img src={src} alt="A London skyscraper"/>
      </div>
      <motion.h2 
      initial={{
        opacity: 0,
        x: -50,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.6,
        },
      }}
      
      viewport={{ once: false }} style={{ y }}>{`${id}`}</motion.h2>
    </section>
  );
}

export const Standards = () =>  {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });
  const standards = [{id:"INNOVATION" , src:ImgINNOVATION }, {id:'EXPERTISE' , src: ImgEXPERTISE }, {id: 'CREATIVITY', src: ImgCREATIVITY}, {id: 'FLEXIBILITY' , src: ImgFLEXIBILITY }, {id: 'ADAPTABILITY', src: ImgADAPTABILITY}];
  return (
    <div className="standards" >
      {standards.map((image) => (
        <Image id={image.id}  src={image.src} />
        
      ))}
      {/* <motion.div className="progress" style={{ scaleX }} /> */} 
    </div>
  );
}
