import React ,{useEffect} from 'react';
import "./home.css";

import { Banner } from "./Banner";
import { Products } from "./Products";
import { CitiesSlider } from "./CitiesSlider";
import { Footer } from "./Footer";
import { Services } from "./Services";
import { Standards } from "./Standards";
import ScrollFlesh from "./scroll.jsx";
import SkewedScroll from "./walid.jsx";

const slides = [
  {
    city: 'GERMANY',
    country: 'Würzburg',
    img: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhDeUExZirZjRkusdijw7yUskcsFIPXuTe5KdLzYKUUhwsNZqpSiFjn7k21yJFKk3H5Sg_3hYNJ3T5jAHtSz3xD1gCjil9f6Hyx8wZi_DYUlElpuGjBr1Pv-_u-DBzTz9Cs_Zuhfyiq4pxTF9J6f6hdTuZzm1oup-y4BzCksHuviBrt2FtptnJsL04yY1s/s1600/WhatsApp%20Image%202024-04-23%20at%2010.24.05_08c2768c.png",
  },
  {
    city: 'France',
    country: 'Lyon',
    img: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh67FybFA-JDvmvhxkogj-bbYRKIQCVo3gkhEO668CZjUjw3RmDkF_Ccdk3z1De8-b8ZIgzeArzSQ77OLNW9yQKOuU0MMkkLm6I8r3FjcqmzDXjxmMNDkzucvys6CoA20khQFQuTJh22q0vlLxPmmAOEjIIWIR25BLX2-j7s__CaLSCQwzD76qw4lD29hU/s1442/article_ImP_Lyon-Ville_1120x490%201.png',
  },
  {
    city: 'Tunisia',
    country: 'Tunis',
    img: 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjkIxexo1H1KjL-YmJrEATSUz-KDlEhb1p5GFvuD_BnoSIMFxpsVevuw4t3fWw5_Vca-9ceaoJKtBpmcIGt_1AbfyI77UoYKv1-Kza9Lbk2lNbYgELMNR1SYIsFPHsqVvJCeHBXUtqYL4W3vb5pGbtO5Gfz4a8wkU-ziC7vQCSUt2a6-JiXVh49DTK3tsc/s1440/av_bourguiba%201.png',
  }
];

const Home = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);


  return (
    <div id="home">
      <Banner />
        <ScrollFlesh style={{ height: '100vh' }} />
      <Products />
      <Services />
      <CitiesSlider slides={slides} />
      <div className="block_b_footer"></div>
      <Footer />
    </div>
  );
}

export default Home;
