import React from "react";

import circle from "../img/circle.png";
import circle1 from "../img/circle1.png";
import { motion } from "framer-motion";
import "./dev.css";
const Dev = () => {
  return (
    <div className="bannersol">
      <div className="bannersoltext me-5 pe-5" id="bannersoltext">
        <motion.h2
          className="me-5 pe-5"
          initial={{
            opacity: 0,
            x: -15,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          viewport={{ once: true }}
        >
          Development that meets
        </motion.h2>
        <motion.h1
          className="me-5 pe-5"
          initial={{
            opacity: 0,
            x: -15,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          viewport={{ once: true }}
        >
          {" "}
          your growth <span>Goals</span>
        </motion.h1>
      </div>
      <motion.img
        src={circle}
        className="end-0 opacity-100"
        initial={{
          opacity: 0,
          y: 15,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
          },
        }}
        viewport={{ once: true }}
      />
      <motion.img
        src={circle1}
        className="end-0 opacity-100 "
        initial={{
          opacity: 0,
          y: 15,
        }}
        whileInView={{
          opacity: 1,
          y : 0,
          transition: {
            duration: 0.6,
          },
        }}
        viewport={{ once: true }}
      />
    </div>
  );
};

export default Dev;
