import React from "react";
import "./innovation.css";
import innovation from "../img/innovation_animation.gif";
import innovationIcon from "../img/innovation_icon.png" ;
import { motion } from "framer-motion";

const Innovation = () => {
  return (
    <div className="innovation" id="innovation">
      <div className="innovation_img">
        <motion.img src={innovation} alt="innovation" 
        initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          
          viewport={{ once: true}}/>
      </div>
      <div className="innovation_title">
        <motion.h1
        initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          
          viewport={{ once: true}}
        >INNOVATION</motion.h1>
      </div>
      <div className="innovation_text">
        <motion.img src={innovationIcon} alt="innovationIcon" 
        initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1 ,
            },
          }}
          
          viewport={{ once: true}}/>
        <motion.p initial={{
                    opacity: 0,
                    x: 30,
                  }}
                  whileInView={{
                    opacity: 1,
                    x: 0,
                    transition: {
                      duration: 1,
                    },
                  }}
                  
                  viewport={{ once: true}}>
          Innovation in the workplace serves as a powerful motivator, driving us
          to explore new ideas and push beyond conventional boundaries. It
          fosters a culture of continuous improvement and creative
          problem-solving, where each challenge is viewed as an opportunity for
          growth and learning.
        </motion.p>
      </div>
    </div>
  );
};

export default Innovation;
