import React , {useState }from "react";
import photo1 from "../img/1.png";
import photo2 from "../img/2.png";
import photo3 from "../img/3.png";
import photo4 from "../img/4.png";
import orange1 from "../img/1orange.png" ;
import orange2 from "../img/2orange.png" ;

import orange3 from "../img/3orange.png" ;

import orange4 from "../img/4orange.png" ;


import { motion } from "framer-motion";

import "./approach.css";
const Approach = () => {
  const [bg1 , setbg1] = useState(photo1) ;
  const [bg2 , setbg2] = useState(photo2) ;
  const [bg3 , setbg3] = useState(photo3) ;
  const [bg4 , setbg4] = useState(photo4) ;

  return (
    <div className="approach">
      <motion.h2
        initial={{
          opacity: 0,
          x: -20,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.6,
          },
        }}
        viewport={{ once: true }}
      >
        OUR APPROACH
      </motion.h2>
      <motion.h1
        initial={{
          opacity: 0,
          x: -20,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 0.8,
          },
        }}
        viewport={{ once: true }}
      >
        How we’ll work <span>Together</span>.
      </motion.h1>
      <div className="approachblock">
        <div className="caseapproach"
         onMouseEnter={() => setbg1(orange1)}
         onMouseLeave={() => setbg1(photo1)} >
          <motion.div
            initial={{
              opacity: 0,
              x: -20,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 0.9,
              },
            }}
            viewport={{ once: true }}
          >
            <img src={bg1} alt="photo1" />
          </motion.div>
          <div>
            <motion.h3
              initial={{
                opacity: 0,
                x: -20,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: true }}
            >
              Design-focused, first and foremost.
            </motion.h3>
          </div>
          <div>
            <motion.p
              initial={{
                opacity: 0,
                x: -20,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                transition: {
                  duration: 1.2,
                },
              }}
              viewport={{ once: true }}
            >
              As a group, we're design-focused over each touchpoint or
              interaction. Everything we do and dream up features a solid design
              affect. We get up and go within the morning, supercharged to
              create a contrast with plan as a top-of-mind arrangement. 
            </motion.p>
          </div>
        </div>
        <div className="caseapproach"
        onMouseEnter={() => setbg2(orange2)}
        onMouseLeave={() => setbg2(photo2)}>
          <motion.div 
          initial={{
            opacity: 0,
            x: 20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.9,
            },
          }}
          viewport={{ once: true }}>
            <img src={bg2} alt="photo2" />
          </motion.div>
          <div>
            <motion.h3  initial={{
            opacity: 0,
            x: 20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}>Vendor no more – we’re part of your Team.</motion.h3>
          </div>
          <div>
            <motion.p 
             initial={{
              opacity: 0,
              x: 20,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 1.2,
              },
            }}
            viewport={{ once: true }}>
              A few brands and businesses require a merchant. Well, that's fair
              not us. We've crossed it out from our virtual word references.
              It's dead to us. Instep, we're a accomplice, enabler, and genuine
              collaborator. We're a group expansion and built around
              unification.  
            </motion.p>
          </div>
        </div>
        <div className="caseapproach"
        onMouseEnter={() => setbg3(orange3)}
        onMouseLeave={() => setbg3(photo3)}>
          <motion.div 
          initial={{
            opacity: 0,
            x: -20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.9,
            },
          }}
          viewport={{ once: true }}>
            <img src={bg3} alt="photo3" />
          </motion.div>
          <div>
            <motion.h3 initial={{
            opacity: 0,
            x: -20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}>Senior teams, aiming for stellar impact.</motion.h3>
          </div>
          <div>
            <motion.p initial={{
            opacity: 0,
            x: -20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1.2,
            },
          }}
          viewport={{ once: true }}>
              All of our benefit, procedure, plan and computerized group
              individuals are senior-level, prepared to require on the world to
              assist you on your mission as a game-changing brand. Usually
              deliberateness, and built around the rationale that to win huge,
              we have to be think huge.
            </motion.p>
          </div>
        </div>
        <div className="caseapproach"
        onMouseEnter={() => setbg4(orange4)}
        onMouseLeave={() => setbg4(photo4)}>
          <motion.div  initial={{
            opacity: 0,
            x: 20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.9,
            },
          }}
          viewport={{ once: true }}>
            <img src={bg4} alt="photo4" />
          </motion.div>
          <div>
            <motion.h3 initial={{
            opacity: 0,
            x: 20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}>M is for Mega results.</motion.h3>
          </div>
          <div>
            <motion.p 
            initial={{
              opacity: 0,
              x: 20,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 1.2,
              },
            }}
            viewport={{ once: true }}>
              To us, results are constantly in the line of sight. If we’re not
              over-delivering on results, we’re not the right partner. From
              day-one we’re hyper-focused on getting the right results and
              continuing to bring those to life with our cross-functional teams.
            </motion.p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Approach;
