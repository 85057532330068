import React from "react";
import mamorange from "../img/mamorange.png";
import { motion } from "framer-motion";
import "./Banner.css";
const Banner = () => {
  return (
    <div className="bannersol">
      <motion.img
        src={mamorange}
        className="start-0"
        initial={{
          opacity: 0,
          x: -15,
        }}
        whileInView={{
          opacity: 0.5,
          x: 0,
          transition: {
            duration: 0.6,
          },
        }}
        viewport={{ once: true }}
      />
      <div className="bannersoltext" id="bannersoltext">
        <motion.h2
          initial={{
            opacity: 0,
            x: 15,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          viewport={{ once: true }}
        >
          Design Powered to
        </motion.h2>
        <motion.h1
          initial={{
            opacity: 0,
            x: 15,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          fuel your growth <span>Goals</span>
        </motion.h1>
      </div>
    </div>
  );
};

export default Banner;
