import "./products.css";
import "animate.css";
import leftcontainer from "../assets/img/leftcontainer.png";
import rightcontainer from "../assets/img/rightcontainer.png";
import white from "../assets/img/white.png";
import white2 from "../assets/img/white2.png";
import { motion } from "framer-motion";
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import { fadeIn } from "./variants";
import page3img from "../assets/img/page3-img.png";
export const Products = () => {
  const [left, setleft] = useState(leftcontainer);
  const [right, setright] = useState(rightcontainer);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 500);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 520);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  return (
    <section className="products">
        <div className="go">
                          <motion.h2
                            initial={{
                              opacity: 0,
                              x: -50,
                            }}
                            whileInView={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                duration: 1.3,
                              },
                            }}
                            viewport={{ once: true }}
                          >
                            Discover more


                          </motion.h2>
                          <Link to="/ouradvantages#BannerAdvantages" >
                          <div
                            className="arrowgraphic"
                           
                          >
                            <div className="arrow">
                              <svg height="100" width="90">
                                <polygon
                                  points="55,25 65,25 60,10"
                                  fill="#CC5803"
                                />

                                <rect
                                  x="58"
                                  y="25"
                                  width="4"
                                  height="165"
                                  fill="#CC5803"
                                />
                              </svg>

                              <div className="circle"></div>
                            </div>
                          </div>
                          </Link>
                        </div>
      <img src={page3img} className="bgimg"/>
      <div>
        <div className="page3-headline">
          <h1>
            We have established procedures to ensure the project's success in an
            effective manner.
          </h1>
        </div>
        <div className="products_container d-flex justify-content-center align-content-center gap-4">
          <div
            className="colonne1 "
            onMouseEnter={() => setleft(white2)}
            onMouseLeave={() => setleft(leftcontainer)}
          >
            <div className="colonne-image">
              <motion.img
                src={left}
                alt="left container"
                style={{}}
                initial={{
                  opacity: 0,
                  x: -50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
              />
            </div>

            <div className="colonne-text">
              <h1
                initial={{
                  opacity: 0,
                  x: 50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
              >
                Design
              </h1>

              <p
                initial={{
                  opacity: 0,
                  x: 50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
              >
                Our strategy and creative processes are intended to deliver the
                best guidance and results that are consistent with your
                company's objectives and goals.
              </p>
            </div>
            <div className="discover">
              <h1
                className="D"
                initial={{
                  opacity: 0,
                  x: 50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
              >
                Discover more
              </h1>
              <Link to="/solutions#design" >
              <div
                className="arrow1"
                initial={{
                  opacity: isWideScreen ? 0 : 1,
                  x: isWideScreen ? 50 : 0 ,
                }}
                whileInView={ isWideScreen ?{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }: {}}
                viewport={{ once: true }}
              >
                <svg height="100" width="90">
                  <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                  <rect x="58" y="25" width="4" height="45" fill="#CC5803" />
                </svg>

                <div className="circle1"></div>
              </div>
              </Link>
            </div>
          </div>
          <div
            className="colonne2"
            onMouseEnter={() => setright(white)}
            onMouseLeave={() => setright(rightcontainer)}
          >
            <div className="colonne-image">
              <motion.img
                src={right}
                alt="right container "
                style={{}}
                initial={{
                  opacity: 0,
                  x: -50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
              />
            </div>
            <div className="colonne-text">
              <h1
                initial={{
                  opacity: 0,
                  x: 50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
              >
                web
              </h1>

              <p
                initial={{
                  opacity: 0,
                  x: 50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
              >
                Our web development team develops responsive and native
                websites.
              </p>
            </div>
            <div className="discover">
              <h1
                className="D"
                initial={{
                  opacity: 0,
                  x: 50,
                }}
                whileInView={{
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }}
                viewport={{ once: true }}
              >
                Discover more
              </h1>
              <Link to="/solutions#web"  >
               {/* <a href="../solutions" onclick={setScrollFlag()}> */}
              <div
                className="arrow1"
                initial={{
                  opacity: isWideScreen ? 0 : 1,
                  x: isWideScreen ?  50 : 0,
                }}
                whileInView={ isWideScreen ?  {
                  opacity: 1,
                  x: 0,
                  transition: {
                    duration: 1,
                  },
                }: {}}
                viewport={{ once: true }}
              >
                <svg height="100" width="90">
                  <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                  <rect x="58" y="25" width="4" height="45" fill="#CC5803" />
                </svg>

                <div className="circle1"></div>
              </div>
              </Link>
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
