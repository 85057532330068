import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

import arrow10 from "../img/arrow10.png";
import plus from "../img/+.png";
import axios from "axios";

const Internshipform = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    education: "",
    field: "",
    graduation_date: "",
    start_date: "",
    end_date: "",
    hours: "",
    cover_letter: "",
    skills: "",
    additional: "",
  });

  const [workExperiences, setWorkExperiences] = useState([
    { job: "", company: "", responsibilities: "" },
  ]);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('No file chosen');

  const navigate = useNavigate();

  const addWorkExperience = (event) => {
    event.preventDefault();
    setWorkExperiences([...workExperiences, { job: "", company: "", responsibilities: "" }]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Check if the input name starts with 'work-' to determine if it belongs to workExperiences
    if (name.startsWith('work-')) {
      const index = parseInt(name.split('-')[1], 10); // Extract the index from name, e.g., 'work-0'
      const field = name.split('-')[2]; // Extract the field (job, company, responsibilities)

      // Create a copy of workExperiences array and update the specific field of the specific index
      const updatedWorkExperiences = [...workExperiences];
      updatedWorkExperiences[index][field] = value;

      // Set the updated workExperiences state
      setWorkExperiences(updatedWorkExperiences);
    } else {
      // For regular form fields, update formData
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setFileName(event.target.files[0]?.name || 'No file chosen');
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    document.getElementById('file').click();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    if (!file) {
      alert("Please upload your resume/CV.");
      return;
    }
    const formDataToSend = new FormData(event.target);
    workExperiences.forEach((experience, index) => {
      formDataToSend.append(`workExperiences[${index}][job]`, experience.job);
      formDataToSend.append(`workExperiences[${index}][company]`, experience.company);
      formDataToSend.append(`workExperiences[${index}][responsibilities]`, experience.responsibilities);
    });


    formDataToSend.append('resume', file);

    try {
      const response = await axios.post('https://vps-b1796450.vps.ovh.net/send-email', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response) {
        navigate('/success');
      } else {
        alert("Error submitting form. Please try again.");
      }
    } catch (error) {
      alert("Error submitting form. Please try again.");
    }
  };

  return (
      <div className="recruitmentform">
        <form onSubmit={handleSubmit}>
          <div className="recruitmentform_section">
            <h1>Personal Information:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="name">Full name</label>
                <br />
                <input required type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="email">Email address</label>
                <br />
                <input required type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="phone">Phone number</label>
                <br />
                <input required type="number" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="address">Address</label>
                <br />
                <input required type="text" id="address" name="address" value={formData.address} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Education:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="education">Current educational institution</label>
                <br />
                <input required type="text" id="education" name="education" value={formData.education} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="field">Field of study</label>
                <br />
                <input required type="text" id="field" name="field" value={formData.field} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="graduation_date">Graduation date</label>
                <br />
                <input required type="date" id="graduation_date" name="graduation_date" value={formData.graduation_date} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Work Experience:</h1>
            <div className="recruitmentform_section">
              {workExperiences.map((experience, index) => (
                  <div key={index} className="recruitmentform_section_form">
                    <div className="oneform">
                      <label htmlFor={`work-${index}-job`}>Job title</label>
                      <br />
                      <input
                          required
                          type="text"
                          id={`work-${index}-job`}
                          name={`work-${index}-job`}
                          value={experience.job}
                          onChange={handleChange}
                      />
                    </div>
                    <div className="oneform">
                      <label htmlFor={`work-${index}-company`}>Company name</label>
                      <br />
                      <input
                          required
                          type="text"
                          id={`work-${index}-company`}
                          name={`work-${index}-company`}
                          value={experience.company}
                          onChange={handleChange}
                      />
                    </div>
                    <div className="oneform">
                      <label htmlFor={`work-${index}-responsibilities`}>Responsibilities</label>
                      <br />
                      <input
                          required
                          type="text"
                          id={`work-${index}-responsibilities`}
                          name={`work-${index}-responsibilities`}
                          value={experience.responsibilities}
                          onChange={handleChange}
                      />
                    </div>
                  </div>
              ))}
            </div>
            <div className="">
              <div className="oneform_button">
                <button onClick={addWorkExperience}>
                  <img src={plus} alt="plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Availability:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="start_date">Start date availability</label>
                <br />
                <input required type="date" id="start_date" name="start_date" value={formData.start_date} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="end_date">End date availability</label>
                <br />
                <input required type="date" id="end_date" name="end_date" value={formData.end_date} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="hours">Hours per week available</label>
                <br />
                <input required type="number" id="hours" name="hours" value={formData.hours} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Cover Letter:</h1>
            <div className="oneform">
              <textarea name="cover_letter" value={formData.cover_letter} onChange={(e) => setFormData({ ...formData, cover_letter: e.target.value })} />
            </div>
          </div>
          <div className="upload">
            <h1>Resume/CV Upload:</h1>
            <div className="oneform">
              <input required type="file" id="file" style={{ display: "none" }} onChange={handleFileChange} />
              <div className="d-flex align-items-center">
                <button onClick={handleButtonClick}>Upload here</button>
                <span className="p-2">{fileName}</span>
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Skills and Qualifications:</h1>
            <div className="oneform">
              <textarea name="skills" value={formData.skills}
                        onChange={(e) => setFormData({...formData, skills: e.target.value})}/>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Additional Information:</h1>
            <div className="oneform">
              <textarea name="additional"/>
            </div>
          </div>
          <div className="form_button_container">
            <button type="submit" className="form_button" id="form_button">
              <div>
                <h2>submit application</h2>
              </div>
              <img src={arrow10} alt="arrow"/>
            </button>
          </div>
        </form>
        <br/>

      </div>
  );
};

export default Internshipform;
