import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import arrow10 from "../img/arrow10.png";
import plus from "../img/+.png";
import "./recruitmentform.css";
import axios from 'axios';



const Recruitmentform = () => {
  const [workExperiences, setWorkExperiences] = useState([
    { job: "", company: "", responsibilities: "" },
  ]);

  const addWorkExperience = (event) => {
    event.preventDefault(); // Prevents the default action (form submission)
    setWorkExperiences([
      ...workExperiences,
      { job: "", company: "", responsibilities: "" },
    ]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newWorkExperiences = [...workExperiences];
    newWorkExperiences[index][name] = value;
    setWorkExperiences(newWorkExperiences);
  };

  const [fileName, setFileName] = useState('No file chosen');
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0] || null);
    setFileName(event.target.files[0]?.name || 'No file chosen');
  };

  const handleButtonClick = (event) => {
    event.preventDefault();
    document.getElementById('file').click();
  };

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    workExperiences.forEach((experience, index) => {
      formData.append(`workExperiences[${index}][job]`, experience.job);
      formData.append(`workExperiences[${index}][company]`, experience.company);
      formData.append(`workExperiences[${index}][responsibilities]`, experience.responsibilities);
    });

    if (file) {
      formData.append('resume', file);
    }

    try {
      const response = await axios.post('https://vps-b1796450.vps.ovh.net/send-email', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        navigate('/success');
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
      <div className="recruitmentform">
        <form onSubmit={handleSubmit}>
          <div className="recruitmentform_section">
            <h1>Personal Information:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="name">Full name</label>
                <br />
                <input required type="text" id="name" name="name" />
              </div>
              <div className="oneform">
                <label htmlFor="email">Email address</label>
                <br />
                <input required type="email" id="email" name="email" />
              </div>
              <div className="oneform">
                <label htmlFor="phone">Phone number</label>
                <br />
                <input required type="tel" id="phone" name="phone" />
              </div>
              <div className="oneform">
                <label htmlFor="address">Address</label>
                <br />
                <input required type="text" id="address" name="address" />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Education:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="education">Current educational institution</label>
                <br />
                <input required type="text" id="education" name="education" />
              </div>
              <div className="oneform">
                <label htmlFor="field">Field of study</label>
                <br />
                <input required type="text" id="field" name="field" />
              </div>
              <div className="oneform">
                <label htmlFor="graduation_date">Graduation date</label>
                <br />
                <input required type="date" id="graduation_date" name="graduation_date" />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Work Experience:</h1>
            <div className="recruitmentform_section">
              {workExperiences.map((experience, index) => (
                  <div key={index} className="recruitmentform_section_form">
                    <div className="oneform">
                      <label htmlFor={`job-${index}`}>Job title</label>
                      <br />
                      <input required
                             type="text"
                             id={`job-${index}`}
                             name="job"
                             value={experience.job}
                             onChange={(event) => handleChange(index, event)}
                      />
                    </div>
                    <div className="oneform">
                      <label htmlFor={`company-${index}`}>Company name</label>
                      <br />
                      <input required
                             type="text"
                             id={`company-${index}`}
                             name="company"
                             value={experience.company}
                             onChange={(event) => handleChange(index, event)}
                      />
                    </div>
                    <div className="oneform">
                      <label htmlFor={`responsibilities-${index}`}>
                        Responsibilities
                      </label>
                      <br />
                      <input required
                             type="text"
                             id={`responsibilities-${index}`}
                             name="responsibilities"
                             value={experience.responsibilities}
                             onChange={(event) => handleChange(index, event)}
                      />
                    </div>
                  </div>
              ))}
            </div>
            <div className="">
              <div className="oneform_button">
                <button onClick={addWorkExperience}>
                  <img src={plus} alt="plus" />
                </button>
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Availability:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="start_date">Start date availability</label>
                <br />
                <input required type="date" id="start_date" name="start_date" />
              </div>
              <div className="oneform">
                <label htmlFor="end_date">End date availability</label>
                <br />
                <input required type="date" id="end_date" name="end_date" />
              </div>
              <div className="oneform">
                <label htmlFor="hours">Hours per week available</label>
                <br />
                <input required type="number" id="hours" name="hours" />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Cover Letter:</h1>
            <div className="oneform">
              <textarea name="cover_letter" />
            </div>
          </div>
          <div className="upload ">
            <h1>Resume/CV Upload:</h1>
            <div className="oneform">
              <input required
                     type="file"
                     id="file"
                     style={{ display: "none" }}
                     onChange={handleFileChange}
              />
              <div className="d-flex align-items-center">
                <button onClick={handleButtonClick}>
                  Upload here
                </button>
                <span className="p-2">{fileName}</span>
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Skills and Qualifications:</h1>
            <div className="oneform">
              <textarea name="skills" />
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Additional Information:</h1>
            <div className="oneform">
              <textarea name="additional" />
            </div>
          </div>
          <div className="form_button_container">
            <button type="submit" className="form_button" id="form_button">
              <div>
                <h2>submit application</h2>
              </div>
              <img src={arrow10} alt="arrow" />
            </button>
          </div>
        </form>
        <br />
      </div>
  );
};

export default Recruitmentform;
