import React from "react";
import "./HowMammutWorks.css" ;  

const HowMammutWorks = () => {
  return (
    <div className="howmammutworks" id="howmammutworks">
        <h1>How <span>Mammut</span> Works</h1>
    </div>
  );
};

export default HowMammutWorks;
