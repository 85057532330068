import { useState } from "react";
import { motion } from "framer-motion";
import Design_photo from "../assets/img/Design.png" ;
import Digital_photo from "../assets/img/digitallearning.png" ;

import Marketing_photo from "../assets/img/Marketing.png" ;

import Dev_photo from "../assets/img/Developpment.png" ;


import "./services.css";

export const Services = () => {
    const [bg , setbg] = useState("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEifQNXB6Y5JRkPx9RJSZYgklgPVjO561AHjaLXnXIPlhWmT0OUFBUlPg5k9LcdZ-X-buoKD-06LMhV6BKxETzKtpkvZg6dUtxsa9NWGdBWlEk7_zN_J0w5s4dh0QxXmAFmzZNWkytTmLpVlEzt1R6ejr2n_IkogeaaYcOs2DU7nq4xMuetziPZ7eIt3cQ4/s1280/mammut%20llk.png");
  return (
    <section class="services" style={{  backgroundImage: `url(${bg})`}}>
      <div>
        <motion.h1
        initial={{
          opacity: 0,
          x: -50,
        }}
        whileInView={{
          opacity: 1,
          x: 0, 
          transition: {
            duration: 0.4,
          },
        }}
        viewport={{ once: false }}>WE SPECIALIZE IN</motion.h1>
        <div>
          <motion.div className="service"
           initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          viewport={{ once: false }}
          onMouseEnter={() => setbg(Design_photo)}
          onMouseLeave={() => setbg("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEifQNXB6Y5JRkPx9RJSZYgklgPVjO561AHjaLXnXIPlhWmT0OUFBUlPg5k9LcdZ-X-buoKD-06LMhV6BKxETzKtpkvZg6dUtxsa9NWGdBWlEk7_zN_J0w5s4dh0QxXmAFmzZNWkytTmLpVlEzt1R6ejr2n_IkogeaaYcOs2DU7nq4xMuetziPZ7eIt3cQ4/s1280/mammut%20llk.png")}>
            <h2>Design Solutions</h2>
            <div className="arrow1">
              <svg height="100" width="90">
                <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                <rect x="58" y="25" width="4" height="45" fill="#CC5803" />
              </svg>

              <div className="circle1"></div>
            </div>
          </motion.div>
          <motion.div className="service"
           initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          viewport={{ once: false }}
                    onMouseEnter={() => setbg(Dev_photo)}
                    onMouseLeave={() => setbg("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEifQNXB6Y5JRkPx9RJSZYgklgPVjO561AHjaLXnXIPlhWmT0OUFBUlPg5k9LcdZ-X-buoKD-06LMhV6BKxETzKtpkvZg6dUtxsa9NWGdBWlEk7_zN_J0w5s4dh0QxXmAFmzZNWkytTmLpVlEzt1R6ejr2n_IkogeaaYcOs2DU7nq4xMuetziPZ7eIt3cQ4/s1280/mammut%20llk.png")}>
            <h2>Development Solutions</h2>
            <div className="arrow1">
              <svg height="100" width="90">
                <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                <rect x="58" y="25" width="4" height="45" fill="#CC5803" />
              </svg>

              <div className="circle1"></div>
            </div>
          </motion.div>
          <motion.div className="service"
           initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: false }}
           onMouseEnter={() => setbg(Marketing_photo)}
           onMouseLeave={() => setbg("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEifQNXB6Y5JRkPx9RJSZYgklgPVjO561AHjaLXnXIPlhWmT0OUFBUlPg5k9LcdZ-X-buoKD-06LMhV6BKxETzKtpkvZg6dUtxsa9NWGdBWlEk7_zN_J0w5s4dh0QxXmAFmzZNWkytTmLpVlEzt1R6ejr2n_IkogeaaYcOs2DU7nq4xMuetziPZ7eIt3cQ4/s1280/mammut%20llk.png")}>
            <h2> Marketing Solutions</h2>
            <div className="arrow1">
              <svg height="100" width="90">
                <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                <rect x="58" y="25" width="4" height="45" fill="#CC5803" />
              </svg>

              <div className="circle1"></div>
            </div>
          </motion.div>
          <motion.div className="service"
           initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1.2,
            },
          }}
          viewport={{ once: false }}
           onMouseEnter={() => setbg(Digital_photo)}
           onMouseLeave={() => setbg("https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEifQNXB6Y5JRkPx9RJSZYgklgPVjO561AHjaLXnXIPlhWmT0OUFBUlPg5k9LcdZ-X-buoKD-06LMhV6BKxETzKtpkvZg6dUtxsa9NWGdBWlEk7_zN_J0w5s4dh0QxXmAFmzZNWkytTmLpVlEzt1R6ejr2n_IkogeaaYcOs2DU7nq4xMuetziPZ7eIt3cQ4/s1280/mammut%20llk.png")}>
            <h2> Digital Learning</h2>
            <div className="arrow1">
              <svg height="100" width="90">
                <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                <rect x="58" y="25" width="4" height="45" fill="#CC5803" />
              </svg>

              <div className="circle1"></div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
