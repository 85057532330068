import React from "react";
import Banner from "./components/Banner"
import Branding from "./components/Branding";
import { motion } from "framer-motion";

const Solutions = () => {

  
    return (
       <div id="design">
        <Banner />
        <Branding />
      </div>
     
    );
  };
  
  export default Solutions;
  