import React from "react";
import "./location.css";
import {motion} from "framer-motion" ; 
import tunis from "../img/tunis.png";
import leon from "../img/leon.png";
import wurz from "../img/wurz.png";

const ContactEmail = () => {
  return (
    <div className="locations">
      <motion.div className="location" 
      initial={{
        opacity: 0,
        x: 30,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.6,
        },
      }}
      viewport={{ once: true }}>
        <img src={wurz} alt="wurz" />
        <div className="location_text">
          <h1>GERMANY.</h1>
          <h2>Wurzburg</h2>
        </div>
      </motion.div>
      <div className="d-flex justify-content-end" >
      <motion.div className="location" 
      initial={{
        opacity: 0,
        x: -30,
      }}
      whileInView={{
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.8,
        },
      }}
      viewport={{ once: true }}>
        <img src={leon} alt="leon" />
        <div className="location_text">
          <h1>FRANCE.</h1>
          <h2>Lyon</h2>
        </div>
      </motion.div></div>
      <motion.div className="location"
      initial={{
        opacity: 0,
        x: 30,
      }}
      whileInView={{
        opacity: 1,
        x: 0 ,       
        transition: {
          duration: 1,
        },
      }}
      viewport={{ once: true }}>
        <img src={tunis} alt="tunis" />
        <div className="location_text">
          <h1>TUNISIA.</h1>
          <h2>TUNIS</h2>
        </div>
      </motion.div>
    </div>
  );
};

export default ContactEmail;
