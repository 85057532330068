import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import arrow10 from "../img/arrow10.png";
import axios from "axios";

const Partnershipform = () => {
  const [formData, setFormData] = useState({
    org_name: '',
    person_name: '',
    email: '',
    phone: '',
    address: '',
    partner_name: '',
    website: '',
    social_media: '',
    purpose: '',
    objectives: '',
    expected_outcomes: '',
    type_of_service: '',
    service_themes: '',
    content_format: '',
    target_audience: '',
    audience_size: '',
    benefits: '',
    expectations: '',
    terms: '',
    pref_method: '',
    availability: '',
    additional_details: ''
  });

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
          'https://vps-b1796450.vps.ovh.net/send-email-partnership',
          formData,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      );

      if (response) {
        navigate('/success');
      } else {
        alert('Error submitting form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (
      <div className="recruitmentform">
        <form onSubmit={handleSubmit}>
          <div className="recruitmentform_section">
            <h1>Organization Information:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="org_name">Organization name</label>
                <br />
                <input required type="text" id="org_name" name="org_name" value={formData.org_name} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="person_name">Contact person's name</label>
                <br />
                <input required type="text" id="person_name" name="person_name" value={formData.person_name} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="email">Email address</label>
                <br />
                <input required type="email" id="email" name="email" value={formData.email} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="phone">Phone number</label>
                <br />
                <input required type="number" id="phone" name="phone" value={formData.phone} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="address">Address (optional)</label>
                <br />
                <input required type="text" id="address" name="address" value={formData.address} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Partner Information:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="partner_name">Partner's name</label>
                <br />
                <input required type="text" id="partner_name" name="partner_name" value={formData.partner_name} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="website">Website (if applicable)</label>
                <br />
                <input required type="text" id="website" name="website" value={formData.website} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="social_media">Social media handles</label>
                <br />
                <input required type="text" id="social_media" name="social_media" value={formData.social_media} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Partnership Details:</h1>
            <div className="oneform">
              <label>Purpose of the partnership</label>
              <textarea name="purpose" value={formData.purpose} onChange={handleChange} />
            </div>
            <div className="oneform">
              <label>Objectives and goals of the partnership</label>
              <textarea name="objectives" value={formData.objectives} onChange={handleChange} />
            </div>
            <div className="oneform">
              <label>Expected outcomes or deliverables</label>
              <textarea name="expected_outcomes" value={formData.expected_outcomes} onChange={handleChange} />
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Service Specifications:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="type_of_service">Type of service</label>
                <br />
                <input required type="text" id="type_of_service" name="type_of_service" value={formData.type_of_service} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="service_themes">Services themes or topics</label>
                <br />
                <input required type="text" id="service_themes" name="service_themes" value={formData.service_themes} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="content_format">Preferred content format</label>
                <br />
                <input required type="text" id="content_format" name="content_format" value={formData.content_format} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Audience Information:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="target_audience">Target audience</label>
                <br />
                <input required type="text" id="target_audience" name="target_audience" value={formData.target_audience} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="audience_size">Audience size and engagement metrics</label>
                <br />
                <input required type="text" id="audience_size" name="audience_size" value={formData.audience_size} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Benefits and Expectations:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="benefits">Benefits for the partner</label>
                <br />
                <input required type="text" id="benefits" name="benefits" value={formData.benefits} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="expectations">Expectations</label>
                <br />
                <input required type="text" id="expectations" name="expectations" value={formData.expectations} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="terms">Terms of compensation</label>
                <br />
                <input required type="text" id="terms" name="terms" value={formData.terms} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Contact Preferences:</h1>
            <div className="recruitmentform_section_form">
              <div className="oneform">
                <label htmlFor="pref_method">Preferred method of communication</label>
                <br />
                <input required type="text" id="pref_method" name="pref_method" value={formData.pref_method} onChange={handleChange} />
              </div>
              <div className="oneform">
                <label htmlFor="availability">Availability for meetings</label>
                <br />
                <input required type="date" id="availability" name="availability" value={formData.availability} onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="recruitmentform_section">
            <h1>Additional Information:</h1>
            <div className="oneform">
              <label>Any additional details or requirements relevant to the partnership</label>
              <textarea name="additional_details" value={formData.additional_details} onChange={handleChange} />
            </div>
          </div>
          <div className="form_button_container">
            <button type="submit" className="form_button" id="form_button">
              <div>
                <h2>Submit Application</h2>
              </div>
              <img src={arrow10} alt="arrow" />
            </button>
          </div>
        </form>
        <br />
      </div>
  );
};

export default Partnershipform;
