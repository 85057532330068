import React from "react";
import "./banneradvantages.css";
import animationadv from "../img/animationadv.gif";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const BannerAdvantages = () => {
  return (
    <div className="banneradvantages">
      <div className="banneradvleft ">
        <div className="banneradvtext">
          <motion.h2 initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          
          viewport={{ once: true}}>Making Your Brand</motion.h2>
          <motion.h1 initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.7,
            },
          }}
          
          viewport={{ once: true}}>Moving Faster</motion.h1>
          <motion.p initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          
          viewport={{ once: true}}>
            In today’s fast-paced digital landscape, effective marketing,
            compelling design, and robust web services are essential for brands
            looking to accelerate their growth.{" "}
          </motion.p>
          <motion.p initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.9,
            },
          }}
          
          viewport={{ once: true}}>
            <br /> By leveraging cutting-edge strategies in digital marketing,
            brands can enhance their online presence, engage with a wider
            audience, and create memorable experiences.
          </motion.p>
        </div>
        <div className="d-flex align-items-center flex-column p-0 m-0">
          <Link to="/contact#contactBanner ">
          <motion.div className="banneradvbutton" initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
            },
          }}
          
          viewport={{ once: true}}>
            <h3 >CONTACT FOR A SERVICE</h3>
          </motion.div>
          </Link>
          <div className="banneradvarrow" onClick={function Scrollfunction() {
  window.scrollBy(0, 800);
 
}}>
            <motion.div className="arrow" 
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 1.1,
              },
            }}
            
            viewport={{ once: true}}>
              <svg height="100" width="90">
                <polygon points="55,25 65,25 60,10" fill="#CC5803" />

                <rect x="58" y="25" width="4" height="55" fill="#CC5803" />
              </svg>

              <div className="circle"></div>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="banneradvright ">
        <div className="banneradvright_column">
          <motion.img src={animationadv} alt="animationadv" initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          
          viewport={{ once: true}}/>
          <motion.img src={animationadv} alt="animationadv"  initial={{
            opacity: 0,
            x: 20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          
          viewport={{ once: true}}/>
          <motion.img src={animationadv} alt="animationadv" initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          
          viewport={{ once: true}}/>
        </div>
        <div className="banneradvright_column">
          <motion.img src={animationadv} alt="animationadv" initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          
          viewport={{ once: true}} />
          <motion.img src={animationadv} alt="animationadv"  initial={{
            opacity: 0,
            x: 20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          
          viewport={{ once: true}}/>
          <motion.img src={animationadv} alt="animationadv" initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          
          viewport={{ once: true}}/>
        </div>
        <div className="banneradvright_column">
          <motion.img src={animationadv} alt="animationadv" initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          
          viewport={{ once: true}}/>
          <motion.img src={animationadv} alt="animationadv" initial={{
            opacity: 0,
            x: 20,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          
          viewport={{ once: true}}/>
          <motion.img src={animationadv} alt="animationadv" initial={{
            opacity: 0,
            x: -50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          
          viewport={{ once: true}} />
        </div>
      </div>
    </div>
  );
};

export default BannerAdvantages;
