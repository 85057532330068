import send from "./img/product.gif" ; 
import arrow10 from "./img/Arrow10 copy.png" ; 
import "./ourproduct.css"
import { useNavigate } from 'react-router-dom';

const Ourproducts = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/'); 
    };
  return (
    <div className="ourproduct">
        <div className="ourproduct_photo" >
            <img src={send} alt="send" />
        </div>
        <div className="ourproduct_title"> 
            <h1>This <span>Section</span></h1>
        </div>
        <div className="ourproduct_title">
            <h1>Will be available soon</h1>
        </div>
        <div className="ourproduct_text">
            <p>STAY TUNED FOR SOME FRESH WORK, COME BACK LATER </p>
        </div>
        <div className="ourproduct_button" onClick={handleButtonClick}>
            <h2>Back</h2>
            <img src={arrow10} alt="arrow5" />
        </div>
    </div>
  );
};

export default Ourproducts;