import React from "react";
import "./innovation.css";
import flexibility from "../img/flexibility_animation.gif";
import flexibilityIcon from "../img/flexibility_icon.png";
import { motion } from "framer-motion";

const Flexibility = () => {
  return (
    <div className="innovation">
      <div className="innovation_img">
        <motion.img
          src={flexibility}
          alt="innovation"
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.6,
            },
          }}
          viewport={{ once: true }}
        />
      </div>
      <div className="innovation_title">
        <motion.h1
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 0.8,
            },
          }}
          viewport={{ once: true }}
        >
          FLEXIBILITY
        </motion.h1>
      </div>
      <div className="innovation_text">
        <motion.img
          src={flexibilityIcon}
          alt="innovationIcon"
          initial={{
            opacity: 0,
            x: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        />
        <motion.p
          initial={{
            opacity: 0,
            x: 30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          Flexibility in the workplace is a key factor for modern businesses,
          fostering a more dynamic and responsive work environment. It allows us
          to manage their work-life balance better, leading to increased job
          satisfaction and productivity. For us, offering flexibility can
          attract top talent and reduce turnover, while also adapting to market
          changes and team needs.
        </motion.p>
      </div>
    </div>
  );
};

export default Flexibility;
