import React ,{useEffect}from "react";
import ContactBanner from "./components/ContactBanner" ;
import ContactEmail from "./components/ContactEmail" ;
import Location from "./components/Location" ;
const Contact = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div id="contactBanner">
        <ContactBanner />
        {/* <ContactEmail /> */}
        <Location />
    </div>
  );
};

export default Contact;
