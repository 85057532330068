import React from "react";
import { Link } from "react-router-dom";
import arrow10 from "../assets/img/Arrow10.png";
import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo007 from "../assets/img/logo007.png";
import arrow10W from "../assets/img/arrow10W1.png";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState("welcome");
  const [scrolled, setScrolled] = useState(false);
  const [arr, setarr] = useState(arrow10);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isClassToggled, setIsClassToggled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsClassToggled(!isClassToggled);
  };
  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <div>
    <div className={`navbar ${scrolled ? "scroll_true" : ""}`}>

        <div className="topbar">
          <div  className="logo">
            <img src={logo007} alt="Logo" />
          </div>

          <div
            className="rightbar"
            id="basic-navbar-nav"
          >
            <Nav className={isClassToggled ? 'sidebar ' : 'navmenu'} id="sidebar">
              <Link
                className={
                  activeLink === "welcome"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => {onUpdateActiveLink("welcome"); if (window.innerWidth < 1241) {
    toggleMenu();
  } ; } }
                to="/#home"
              >
                <div class="line">WELCOME TO MAMMUT</div>
              </Link>
              <Link
                className={
                  activeLink === "why us" ? "active navbar-link" : "navbar-link"
                }
                onClick={() => {onUpdateActiveLink("why us")  ;  if (window.innerWidth < 1241) {
    toggleMenu();
  }  ;}}
                to="/ouradvantages#BannerAdvantages"
              >
                <div class="line">OUR ADVANTAGES</div>
              </Link>
              <Link
                className={
                  activeLink === "solutions"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => {onUpdateActiveLink("solutions") ;  if (window.innerWidth < 1241) {
    toggleMenu();
  }  ;}}
                to="/solutions#design"
              >
                <div class="line">OUR SOLUTIONS</div>
              </Link>
              <Link
                className={
                  activeLink === "products"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => {onUpdateActiveLink("products")  ;  if (window.innerWidth < 1241) {
    toggleMenu();
  }  ;}}
                to="/ourproducts"
              >
                <div class="line">OUR PRODUCTS</div>
              </Link>
              <Link
                className={
                  activeLink === "join us"
                    ? "active navbar-link"
                    : "navbar-link"
                }
                onClick={() => {onUpdateActiveLink("join us")  ;  if (window.innerWidth < 1241) {
    toggleMenu();
  }  ;}}
                to="/beoneofus#bannerbeoneofus"
              >
                <div class="line"> BE ONE OF US</div>
              </Link>
            </Nav>
            <span
              className="navbar-text"
              onMouseEnter={() => setarr(arrow10W)}
              onMouseLeave={() => setarr(arrow10)}
            >
              <Link to="/contact#contactBanner" >
                {/* <i class="bi bi-arrow-right"></i> */}
                {/* <ArrowRight size={25} /> */}
                <button className="vvd">
                  <span></span>CONTACT
                  <div className="arrow10">
                    <img src={arr} alt="arrrow" />
                  </div>
                </button>
              </Link>
            </span>
            <div className="menuIcon">
      {isMenuOpen ? (
        <div id="x-menu" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="46"
            fill="#CC5803"
            className="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
          </svg>
        </div>
      ) : (
        <div id="menu-icon" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="46"
            fill="#CC5803"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </div>
      )}
    </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
